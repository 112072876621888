$login-form-width: 340px;

.login-page-container {

  .logo {
    margin: 40px;
    height: 200px;

    @media screen and (max-height: 600px) {
      margin: 15px;
      height: 150px; } }

  .form-grid-item {
    @media screen and (max-width: $login-form-width) {
      width: 100%; } }

  .form-container {
    width: $login-form-width;
    @media screen and (max-width: $login-form-width) {
      width: 100%; } }

  .header {
    text-align: center;
    font-weight: 400;
    font-size: 1.5em;
    margin: 5px 0 15px; }

  .login-loader {
    margin-right: 10px; } }
