$drawer-width: 240px;

.app-bar {
  z-index: 9999; }

.grow {
  flex-grow: 1; }

.drawer {
  width: $drawer-width;
  flex-shrink: 0;
  z-index: 1; }

.toolbar {
  height: 50px; }

.logo {
  margin-right: 10px; }

.logo-container {
  text-align: center; }

.galera-cluster-logo {
  width: 220px;
  margin: 10px 0; }
